<script>
  import { Section } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let title = undefined;
  export let theme = undefined;
  export let cta = undefined;
</script>

<Section {title} {theme} {cta} class="section {className}" titleTag="h2">
  <slot />
</Section>
