<script>
  import { GridContainer, Grid } from "@ObamaFoundation/of-design-system";
  import { getContext, setContext } from "svelte";

  /** @type { string } */
  let className = "";
  export { className as class };

  const IGNORE_LIST = ["embedded-entry-block", "document", "list-item"];
  const isContainerized = !!getContext("containerized");
  const isSectionDescendant = !!getContext("sectionDescendant");
  const isSplitLayoutDescendant = !!getContext("splitLayoutDescendant");
  const nodeContext = getContext("nodeContext");
  const nodeType = nodeContext?.nodeType;
  const previousNode = nodeContext?.previousNodeType?.nodeType;

  let shouldIgnore = IGNORE_LIST.includes(nodeType);

  if (!shouldIgnore) {
    if (nodeType === "paragraph" && previousNode === "list-item") {
      shouldIgnore = true;
    }

    if (nodeType === "unordered-list" && previousNode === "list-item") {
      shouldIgnore = true;
    }

    if (nodeType === "ordered-list" && previousNode === "list-item") {
      shouldIgnore = true;
    }

    if (nodeType === "hyperlink" && previousNode === "paragraph") {
      shouldIgnore = true;
    }
  }

  const isLayoutComponentDescendant = isSectionDescendant || isSplitLayoutDescendant;
  const isSmartLayoutContainerized =
    !isContainerized && isLayoutComponentDescendant && !shouldIgnore;

  if (isSmartLayoutContainerized) {
    setContext("containerized", true);
  }
</script>

{#if isContainerized || shouldIgnore}
  <slot />
{:else if isSmartLayoutContainerized || nodeType === "blockquote"}
  <GridContainer containerClass={className}>
    <slot />
  </GridContainer>
{:else}
  <GridContainer containerClass={className}>
    <Grid>
      <div class="sm:col-span-4 md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4">
        <slot />
      </div>
    </Grid>
  </GridContainer>
{/if}
